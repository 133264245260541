import React from 'react';
import { ICard } from '../cards';
import './Card.scss';

interface ICardProps {
  data: ICard;
}

function Card({ data }: ICardProps): JSX.Element {
  return (
    <div className="card">
      <img
        className="card__image"
        src={`https://picsum.photos/id/${data.id}/280/280`}
        alt={data.id}
      />
      <ul className="card__info">
        <li className="card__info-row">{`Image ID: ${data.id}`}</li>
        <li className="card__info-row">{`Author: ${data.author}`}</li>
        <li className="card__info-row">{`Original size: ${data.width}x${data.height}`}</li>
        <li className="card__links">
          <a
            className="card__link"
            href={data.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Unsplash link
          </a>
          <a
            className="card__link"
            href={data.downloadUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Card;
