import React from 'react';
import cards from '../cards';
import Card from './Card';
import './CardList.scss';

function CardList(): JSX.Element {
  return (
    <ul className="cardlist">
      {cards.map(card => (
        <Card key={card.id} data={card} />
      ))}
    </ul>
  );
}

export default CardList;
