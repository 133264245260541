import React from 'react';
import './App.scss';
import CardList from './components/CardList';
import SearchBar from './components/SearchBar';

function App(): JSX.Element {
  return (
    <>
      <SearchBar />
      <CardList />
    </>
  );
}

export default App;
