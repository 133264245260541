export interface ICard {
  id: string;
  author: string;
  width: number;
  height: number;
  url: string;
  downloadUrl: string;
}

const cards: ICard[] = [
  {
    id: '1068',
    author: 'Padurariu Alexandru',
    width: 7117,
    height: 4090,
    url: 'https://unsplash.com/photos/iNmouRApXYM',
    downloadUrl: 'https://picsum.photos/id/1068/7117/4090',
  },
  {
    id: '1069',
    author: 'Marat Gilyadzinov',
    width: 3500,
    height: 2333,
    url: 'https://unsplash.com/photos/wpTWYBll4_w',
    downloadUrl: 'https://picsum.photos/id/1069/3500/2333',
  },
  {
    id: '107',
    author: 'Lukas Schweizer',
    width: 5760,
    height: 3840,
    url: 'https://unsplash.com/photos/9VWOr22LhVI',
    downloadUrl: 'https://picsum.photos/id/107/5760/3840',
  },
  {
    id: '1070',
    author: 'Sean Stratton',
    width: 5472,
    height: 3648,
    url: 'https://unsplash.com/photos/3I5j50pIXvU',
    downloadUrl: 'https://picsum.photos/id/1070/5472/3648',
  },
  {
    id: '1071',
    author: 'Tim Stief',
    width: 3000,
    height: 1996,
    url: 'https://unsplash.com/photos/uzBiLWpjQEQ',
    downloadUrl: 'https://picsum.photos/id/1071/3000/1996',
  },
  {
    id: '1072',
    author: 'jamie mink',
    width: 3872,
    height: 2592,
    url: 'https://unsplash.com/photos/5Qwz2KyfIBE',
    downloadUrl: 'https://picsum.photos/id/1072/3872/2592',
  },
  {
    id: '1073',
    author: 'Patrick Tomasso',
    width: 5472,
    height: 3648,
    url: 'https://unsplash.com/photos/Oaqk7qqNh_c',
    downloadUrl: 'https://picsum.photos/id/1073/5472/3648',
  },
  {
    id: '1074',
    author: 'Samuel Scrimshaw',
    width: 5472,
    height: 3648,
    url: 'https://unsplash.com/photos/sseiVD2XsOk',
    downloadUrl: 'https://picsum.photos/id/1074/5472/3648',
  },
];

export default cards;
