import React, { useState } from 'react';
import './SearchBar.scss';

function SearchBar(): JSX.Element {
  const [value, setValue] = useState('');

  return (
    <form className="search">
      <input
        className="search__input"
        type="text"
        placeholder="Search"
        onChange={e => setValue(e.target.value)}
        value={value}
      />
      <button
        className="search__reset"
        type="button"
        onClick={() => setValue('')}
      >
        ✖
      </button>
    </form>
  );
}

export default SearchBar;
